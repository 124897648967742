import { Controller } from "@hotwired/stimulus"
import { handleError } from "../utils/errors"
import volumeStore from "../stores/volume_store"
import ga from "../utils/ga"
import screenfull from "screenfull"
import { getMinutesSecondsTime } from "../utils/time"

export default class extends Controller {
  static targets = [
    "loadingIndicator",
    "player",
    "progressBar",
    "progressBarIndicator",
    "link",
    "controlsContainer",
    "muteButton",
    "displayTime",
    "current",
    "duration",
  ]

  static values = { itemId: String }

  static classes = ["isHovered", "muteIcon", "fullscreen", "playing", "hidden"]

  initialize() {
    this.videoPlayTimeout = null
    this.hideCursorTimeout = null
    this.videoResetTimeout = null
    this.playPromise = null
    this.wasTouchMoveDetected = false
    this.reportedProgressEvents = []
    this.playing = false
  }

  connect() {
    this.displayMuteToggle()
    this.updateMuteUI()
    this.observer = new IntersectionObserver(this.onIntersection)
    this.observer.observe(this.element)
  }

  disconnect() {
    // some observers may already have been destroyed when they came into the view
    if (this.observer) {
      this.observer.unobserve(this.element)
    }
  }

  get videoHasLoaded() {
    return this.playerTarget.dataset.videoHasLoaded === "true"
  }

  set videoHasLoaded(value) {
    this.playerTarget.dataset.videoHasLoaded = value
  }

  get isScrubbing() {
    return this.progressBarTarget.dataset.isScrubbing === "true"
  }

  set isScrubbing(isScrubbing) {
    this.progressBarTarget.dataset.isScrubbing = isScrubbing
  }

  displayMuteToggle = () => {
    const video = this.playerTarget

    video.addEventListener(
      "loadeddata",
      () => {
        const hasAudio =
          video.mozHasAudio ||
          Boolean(video.webkitAudioDecodedByteCount) ||
          Boolean(video.audioTracks && video.audioTracks.length)

        this.muteButtonTargets.forEach((btn) => {
          btn.style.display = hasAudio ? "inline" : "none"
        })
      },
      { once: true },
    )
  }

  onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.activatePlayerPoster()
        this.observer.unobserve(this.element)
        this.observer = null
      }
    })
  }

  onMouseEnter(e) {
    this.updateHoveredState(true)
    this.startVideo()
  }

  onMouseLeave(e) {
    this.updateHoveredState(false)
    this.pauseVideo()
  }

  onMouseMove(e) {
    this.scheduleHidingCursorAndControls()
  }

  onVideoProgress = () => {
    const { currentTime, duration } = this.playerTarget
    const progress = currentTime > 0 && duration > 0 ? (currentTime / duration) * 100 : 0

    this.updateProgressBar(progress)
    this.updateCurrentTime(currentTime)

    if (!this.playerTarget.ended && !this.playerTarget.paused) {
      requestAnimationFrame(this.onVideoProgress)
    }
  }

  onProgressClick(e) {
    if (!this.isScrubbing) {
      this.seekVideo(this.calculateJumpToPercent(e))
    }
  }

  onProgressDragStart(e) {
    this.isScrubbing = true
    this.seekVideo(this.calculateJumpToPercent(e))
  }

  onProgressDragEnd(e) {
    if (e.cancelable) e.preventDefault()
    this.isScrubbing = false
  }

  onProgressDrag(e) {
    if (this.isScrubbing) {
      this.seekVideo(this.calculateJumpToPercent(e))
    }
  }

  onProgressMouseOut(e) {
    if (this.isScrubbing) this.onProgressDragEnd(e)
  }

  updateMuteUI() {
    this.playerTarget.muted = volumeStore.volume === 0

    if (this.playerTarget.muted) {
      this.element.classList.add(this.muteIconClass)
    } else {
      this.element.classList.remove(this.muteIconClass)
    }
  }

  onToggleSoundClick() {
    volumeStore.toggleMute()
    // the event from the VolumeStore will be slightly delayed. Calling
    // onVolumeChange will ensure the sound is turned on immediately
    this.updateMuteUI()
  }

  onToggleFullScreenClick(event) {
    if (screenfull && screenfull.isEnabled) {
      if (!screenfull.isFullscreen) {
        screenfull.on("change", this.onFullcreenChange)
        this.reportVideoFullscreenEvent()
      }

      // Cypress tests can not enter fullscreen so
      // this will skip going fullscreen in tests
      if ("isTrusted" in event && !event.isTrusted) return

      screenfull.toggle(this.element)
    } else {
      if (this.playerTarget.webkitSupportsFullscreen) {
        this.startVideo()
        this.playerTarget.webkitEnterFullscreen()
      }
    }
  }

  onFullcreenChange = () => {
    if (screenfull) {
      if (screenfull.isFullscreen) {
        this.element.classList.add(this.fullscreenClass)
        this.startVideo()
        this.discardVideoReset()
      } else {
        this.element.classList.remove(this.fullscreenClass)
        screenfull.off("change", this.onFullcreenChange)
        this.pauseVideo()
      }
    }
  }

  onTouchStart() {
    this.wasTouchMoveDetected = false
  }

  onTouchMove() {
    this.wasTouchMoveDetected = true
  }

  onTouchEnd(event) {
    if (event.cancelable) event.preventDefault()
    if (!this.wasTouchMoveDetected) {
      // show menu 3 seconds when you touch the screen
      this.scheduleHidingCursorAndControls()
      // notify all the other videos to stop
      this.emitActivePlayerChangeEvent()
      this.togglePlay()
    }
  }

  activatePlayerPoster() {
    this.playerTarget.poster = this.playerTarget.dataset.poster
  }

  togglePlay() {
    this.playerTarget.paused ? this.startVideo() : this.pauseVideo(false)
  }

  seekVideo(jumpToPercent) {
    const jumpToTime = this.playerTarget.duration * jumpToPercent

    if (isNaN(jumpToTime) || this.playerTarget.currentTime <= 0) return

    this.playerTarget.currentTime = jumpToTime
  }

  calculateJumpToPercent(event) {
    const progressbar = event.target.getBoundingClientRect()
    const playerRect = this.playerTarget.getBoundingClientRect()
    const delta = event.clientX - progressbar.left
    const progress = Math.round((delta * 100) / playerRect.width)
    return progress / 100
  }

  scheduleHidingCursorAndControls() {
    this.linkTarget.style.cursor = "inherit"
    this.controlsContainerTarget.style.opacity = 1
    this.updateHoveredState(true)

    clearTimeout(this.hideCursorTimeout)
    this.hideCursorTimeout = setTimeout(() => {
      this.linkTarget.style.cursor = "none"
      this.controlsContainerTarget.style.opacity = 0
    }, 3000)
  }

  switchLoadingIndicator() {
    if (!this.videoHasLoaded && this.playing) {
      this.loadingIndicatorTarget.style.display = "flex"
      this.playerTarget.addEventListener("loadeddata", this.onVideoLoad)
    } else {
      this.loadingIndicatorTarget.style.display = "none"
    }
  }

  onVideoLoad = () => {
    this.loadingIndicatorTarget.style.display = "none"
    this.videoHasLoaded = true
    this.playerTarget.removeEventListener("loadeddata", this.onVideoLoad)
  }

  startVideo() {
    this.discardScheduledVideoPlay()

    this.videoPlayTimeout = setTimeout(() => {
      this.discardVideoReset()
      this.playing = true
      this.switchLoadingIndicator()
      // we consider the video ended in the last 200 ms or the last 1%
      // so poll this every 100ms to be safe
      // the timeupdate event doesn't seem to fire frequently enough
      clearInterval(this.playerProgressInterval)
      this.playerProgressInterval = setInterval(this.reportProgressEvent.bind(this), 100)
      this.playPromise = this.playerTarget.play()
      if (this.playPromise !== undefined) {
        this.playPromise
          .then(() => {
            this.element.classList.add(this.playingClass)
            this.setDuration()
            this.onVideoProgress()
            this.showDisplayTime(true)
          })
          .catch((error) => {
            this.playing = false
            clearInterval(this.playerProgressInterval)
            this.switchLoadingIndicator()
            this.ignoreNotAllowedError(error)
          })
      }
    }, 300)
  }

  setDuration() {
    this.durationTargets.forEach((duration) => {
      duration.innerHTML = getMinutesSecondsTime(this.playerTarget.duration)
    })
  }

  updateCurrentTime(currentTime) {
    this.currentTargets.forEach((current) => {
      current.innerHTML = getMinutesSecondsTime(currentTime)
    })
  }

  showDisplayTime(isDisplay) {
    this.displayTimeTargets.forEach((displayTime) => {
      isDisplay ? displayTime.classList.remove(this.hiddenClass) : displayTime.classList.add(this.hiddenClass)
    })
  }

  discardScheduledVideoPlay() {
    if (this.videoPlayTimeout) {
      clearTimeout(this.videoPlayTimeout)
      this.videoPlayTimeout = null
    }
  }

  emitActivePlayerChangeEvent() {
    const detail = { player: this.playerTarget }
    const event = new CustomEvent("activeVideoPlayerChange", { detail })
    window.dispatchEvent(event)
  }

  pauseForNewActivePlayer(event) {
    if (this.playerTarget === event.detail.player) return
    if (this.playing) {
      this.pauseVideo()
    } else if (this.playerTarget.currentTime > 0) {
      this.scheduleVideoReset()
    }
  }

  pauseVideo(videoReset = true) {
    this.discardScheduledVideoPlay()
    this.discardVideoReset()
    this.playing = false
    this.element.classList.remove(this.playingClass)

    clearInterval(this.playerProgressInterval)

    if (this.playPromise) {
      this.playPromise.then(() => this.playerTarget.pause()).catch(this.ignoreNotAllowedError)
    }

    if (videoReset) {
      this.scheduleVideoReset()
    }
  }

  scheduleVideoReset() {
    this.videoResetTimeout = setTimeout(() => {
      // Resets the media element and restarts the media resource. Any pending events are discarded.
      // https://dev.w3.org/html5/spec-preview/media-elements.html#dom-media-load
      if (this.playPromise) {
        this.playPromise.then(() => {
          const previousSrc = this.playerTarget.src
          this.playerTarget.src = ""
          this.playerTarget.load()
          this.playerTarget.src = previousSrc
          this.updateProgressBar(0)
          this.showDisplayTime(false)
        })
      }
    }, 0)
  }

  discardVideoReset() {
    if (this.videoResetTimeout) {
      clearTimeout(this.videoResetTimeout)
      this.videoResetTimeout = null
    }
  }

  updateHoveredState(isHovered) {
    if (isHovered) {
      this.element.classList.add(this.isHoveredClass)
    } else {
      this.element.classList.remove(this.isHoveredClass)
    }
  }

  updateProgressBar(progress) {
    progress = Math.floor(progress)
    this.progressBarTarget.setAttribute("aria-valuenow", progress)
    this.progressBarIndicatorTarget.style.width = `${progress}%`
  }

  reportVideoFullscreenEvent() {
    ga("send", {
      hitType: "event",
      eventCategory: "Video",
      eventAction: "Full screen preview",
      eventLabel: this.itemIdValue,
    })
  }

  reportProgressEvent() {
    const currentTime = this.playerTarget.currentTime
    const duration = this.playerTarget.duration

    // it hasn't quite started yet, or something else went wrong.
    if (isNaN(currentTime) || isNaN(duration)) {
      return
    }

    let eventName = null
    const progress = (currentTime / duration) * 100

    if (progress < 25) {
      eventName = "video_start"
    } else if (progress < 50) {
      eventName = "25_percent"
    } else if (progress < 75) {
      eventName = "50_percent"
    } else if (progress <= 99 && duration - currentTime > 0.2) {
      eventName = "75_percent"
    } else {
      eventName = "video_complete"
    }

    if (this.reportedProgressEvents.indexOf(eventName) >= 0) return

    ga("send", {
      hitType: "event",
      eventCategory: "video player - search",
      eventAction: eventName,
      eventLabel: this.playerTarget.src,
    })

    this.reportedProgressEvents.push(eventName)

    if (this.reportedProgressEvents.length === 5) {
      // we have the whole team
      clearInterval(this.playerProgressInterval)
    }
  }

  ignoreNotAllowedError = (error) => {
    // see https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
    if (error.name !== "NotAllowedError") handleError(error)
  }
}
