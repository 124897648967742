/* eslint no-console:0 */
import "@webcomponents/custom-elements"
import "abortcontroller-polyfill/dist/polyfill-patch-fetch"
import { loadGAScript, handleGAOptedOutEvent } from "../utils/google_analytics"
import { deferLoadGTMScript } from "../utils/google_tag_manager"
import { disableButtonHandler } from "../utils/turbolinks"
import { loadControllers } from "../controllers"
import { initializeAffiliateRecording, handleAffiliateOptedOutEvent } from "../utils/affiliate_recording"
import { handleExperimentOptedOutEvent } from "../utils/experiments_opt_out"
import ga from "../utils/ga"
import { navigator } from "@hotwired/turbo"
import gaQueue from "../utils/ga_queue"
import { reportExperimentEnrolmentToGa } from "../utils/experiments_ga"

loadControllers()
loadGAScript()
handleGAOptedOutEvent()
deferLoadGTMScript()
disableButtonHandler(document)
initializeAffiliateRecording(window.location, document.referrer)
handleAffiliateOptedOutEvent()
handleExperimentOptedOutEvent()

document.addEventListener("turbo:click", (event) => {
  document.body.classList.add("loading")
  if (event.detail.url.endsWith("#content")) {
    const content = document.getElementById("content")
    if (content) {
      content.scrollIntoView()
    }
  }
})

document.addEventListener("turbo:before-visit", () => {
  document.body.classList.add("loading")
})

document.addEventListener("turbo:render", () => {
  document.body.classList.remove("loading")
})

document.addEventListener("turbo:submit-end", async (event) => {
  // Turbo doesn't handle 404 page requests very well.
  // The browser url is not updated, and the back button does not work.
  // This code prevents that from happening.
  if (!event.detail.success && event.detail.fetchResponse?.response?.url) {
    event.preventDefault()
    navigator.history.push(new URL(event.detail.fetchResponse.response.url))
  }
})

document.addEventListener("turbo:before-render", () => {
  // We pause sending GA events just before rendering the page.
  // This stops the any stimulus controllers from sending events attributed to the wrong URL.
  // Stimulus controllers sending events during connect() can use the gaQueue() method (instead of "ga")
  // and these events are sent after the page view event in "turbo:load" below.
  gaQueue.queue.pause()
})

document.addEventListener("turbo:load", (event) => {
  if (typeof window.Cookiebot === "object" && "hasResponse" in window.Cookiebot && !window.Cookiebot.hasResponse) {
    window.Cookiebot.show()
  }

  // We send the GA page view on a "turbo:load".
  // The "turbo:load" event only files ONCE when the page is fully loaded (as opposed to turbo:render which
  // can fire TWICE on a page load if loading from memory cache).
  // We put this in 'application.js' instead of a stimulus controller because this code only runs ONCE so it's
  // much easier to add global event listeners here.
  ga("set", "location", document.URL)

  // Hook in here to set our experiment GA variables, before reporting the pageview below.
  reportExperimentEnrolmentToGa()

  // After the page view is sent, we want to send out any queued up GA events from stimulus controllers.
  gaQueue.queue.unpause()

  // When campaign params are set (e.g. utm_source=...), a session is created,
  // however at the next pageview sent without a fullpage refresh (like a turbolink
  // app) the content of http header is read and a new session is started. Nulling the
  // referrer value in the tracker at the end of the page on every pageview resolves this.
  ga("set", { referrer: null })
})

window.addEventListener("beforeunload", () => {
  // in case there's anything left in the queue, we unpause/process it.
  gaQueue.queue.unpause()
})
