import { Controller } from "@hotwired/stimulus"
import gaQueue from "../utils/ga_queue"
import { sendAnalyticsEvent } from "@envato/gtm-analytics"

export default class extends Controller {
  static get targets() {
    return ["item"]
  }

  static values = {
    additionalParams: Object,
    eventCategory: { type: String, default: "Ecommerce" },
  }

  connect() {
    if (this.itemTargets.length > 0) {
      // GA4
      const itemsByList = this.getItemsByList()

      for (const listName of Object.keys(itemsByList)) {
        if (Object.prototype.hasOwnProperty.call(itemsByList, listName)) {
          const itemsInList = itemsByList[listName]

          const ecommercePayload = {
            item_list_name: listName,
            items: itemsInList,
            currency: "USD",
          }

          sendAnalyticsEvent({
            eventName: "view_item_list",
            eventType: "user",
            searchTerm: this.additionalParamsValue.dimension24,
            searchResults: this.additionalParamsValue.dimension25,
            ecommerce: ecommercePayload,
          })
        }
      }

      // GA3
      this.itemTargets.forEach((item) => this.trackImpression(item))

      gaQueue("send", "event", this.eventCategoryValue, "view", "Product impression", {
        nonInteraction: true,
        ...this.additionalParamsValue,
      })
    }
  }

  trackImpression(item) {
    gaQueue("ec:addImpression", this.impressionData(item))
  }

  getItemsByList() {
    return this.itemTargets.reduce((result, item) => {
      const listName = item.dataset.impressionList

      result[listName] = result[listName] || []

      result[listName].push(this.payloadData(item))

      return result
    }, {})
  }

  payloadData(item) {
    // id of Elements item contains capital letters
    // whereas Market item id only has digits
    const hasAlpha = item.dataset.itemId.match(/[A-Z]/)
    const itemId = hasAlpha ? item.dataset.itemId : parseInt(item.dataset.itemId)
    const [itemCategory, itemCategory2, itemCategory3] = this.extractCategoryParts(item)

    const payloadData = {
      itemId,
      index: parseInt(item.dataset.impressionPosition),
      itemName: item.dataset.impressionName,
      itemBrand: item.dataset.impressionBrand,
      affiliation: item.dataset.site,
      price: parseInt(item.dataset.price),
      quantity: 1,
      itemCategory,
      itemCategory2,
      itemCategory3,
    }

    return payloadData
  }

  impressionData(item) {
    // id of Elements item contains capital letters
    // whereas Market item id only has digits
    const hasAlpha = item.dataset.itemId.match(/[A-Z]/)
    const id = hasAlpha ? item.dataset.itemId : parseInt(item.dataset.itemId)

    const impressionData = {
      id,
      position: parseInt(item.dataset.impressionPosition),
      name: item.dataset.impressionName,
      list: item.dataset.impressionList,
      brand: item.dataset.impressionBrand,
    }

    const impressionCategory = item.dataset.impressionCategory
    if (impressionCategory) {
      impressionData.category = impressionCategory
    }

    return impressionData
  }

  extractCategoryParts(item) {
    const impressionCategory = item.dataset.impressionCategory
    if (!impressionCategory) return []

    if (impressionCategory.includes(item.dataset.site)) {
      return impressionCategory.split("/").slice(2)
    } else {
      return impressionCategory.split("/")
    }
  }
}
