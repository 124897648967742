// This controller facilitates sending custom events to Google Analytics as a
// result of user interactions with the page.
//
// Usage:
//
// <body data-controller="analytics-event">
//   <a href="..."
//      data-action="analytics-event#send"
//      data-analytics-event="<%= {someKey: "some_value"}.to_json %>"
//   >
//     Hello World
//   </a>
// </body>

import { Controller } from "@hotwired/stimulus"
import ga from "../utils/ga"
import gaQueue from "../utils/ga_queue"

export default class extends Controller {
  static targets = ["immediate"]

  connect() {
    this.immediateTargets.forEach((element) => {
      // Send events via a GA queue, so they're fired after the page view event.
      this.sendQueuedEvents(this.extractPayloadFromElement(element))
    })
  }

  send(event) {
    this.sendEvents(this.extractPayloadFromElement(event.target))
  }

  sendAlt(event) {
    this.sendEvents(this.extractPayloadFromElement(event.target, { alt: true }))
  }

  // private

  sendEvents(payload) {
    // TODO: @arman a simple refactor will help avoid this conditional. I will
    //       come back to do this once done with bringing over all the events
    payload = Array.isArray(payload) ? payload : [["send", payload]]
    payload.forEach((entry) => ga(...entry))
  }

  sendQueuedEvents(payload) {
    // TODO: @arman a simple refactor will help avoid this conditional. I will
    //       come back to do this once done with bringing over all the events
    payload = Array.isArray(payload) ? payload : [["send", payload]]
    payload.forEach((entry) => gaQueue(...entry))
  }

  extractPayloadFromElement(el, options = {}) {
    let nodeWithCustomData, rawData

    if (options.alt) {
      nodeWithCustomData = el.closest("[data-analytics-event-alt]")
      rawData = nodeWithCustomData.dataset.analyticsEventAlt
    } else {
      nodeWithCustomData = el.closest("[data-analytics-event]")
      rawData = nodeWithCustomData.dataset.analyticsEvent
    }
    return JSON.parse(rawData)
  }
}
