import { Consent, deferRun } from "./consent"
import { loadScript } from "./load_script"

// If there is already marketing consent, we run the script
// straight away, otherwise we wait for a consent event
// that includes marketing consent
export const deferLoadGTMScript = () => {
  const marketingId = document.body.dataset.googleTagManagerMarketingId
  const statisticsId = document.body.dataset.googleTagManagerStatisticsId

  const startGTM = (gtmId) => {
    const w = window
    w.dataLayer = w.dataLayer || []
    w.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    loadScript(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`)
  }

  deferRun(() => startGTM(marketingId), Consent.marketing)
  deferRun(() => startGTM(statisticsId), Consent.statistics)
}
